// src/components/common/Modal.js

import React from 'react';
import { IoCloseCircle } from "react-icons/io5";

export const Modal = ({ children, toggleModal }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-xl relative">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={toggleModal}
        >
         <IoCloseCircle className='text-[25px]' />
        </button>
        {children}
      </div>
    </div>
  );
};
