// src/App.js

import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SSOSignin } from "./components/signin/SSOSignin";
import { Dashboard } from "./components/modules/Dashboard";
import { Layout } from "./components/constants/Layout";
import { Timesheet } from "./components/modules/Timesheet";
import { Report } from "./components/modules/Report";
import { Settings } from "./components/modules/Settings";
import { Employee } from "./components/modules/Employee";
import { TimeSheetOne } from "./components/modules/TimeSheetOne";
import { Project } from "./components/modules/Project";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      const { username } = instance?.getActiveAccount();
      console.log("Email : ", username)
    }
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SSOSignin isAuthenticated={isAuthenticated} />} />
        <Route path="/" element={<Layout />}>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route index path="timesheets" element={<Timesheet />} />
          <Route path="reports" element={<Report />} />
          <Route path="settings" element={<Settings />} />
          <Route path="employee" element={<Employee />} />
          <Route path="timesheet1" element={<TimeSheetOne />} />
          <Route path="projects" element={<Project />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
