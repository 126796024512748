// src/components/modules/Timesheet.js

import React, { useState, useMemo } from "react";
import CustomTable from "../common/CustomTable";
import { DatePicker, Input } from 'antd';
import { FaRegEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { Modal } from "../common/Modal";
import { Select, Typography } from 'antd';
import { LuPlusCircle } from "react-icons/lu";
import { Switch } from 'antd';

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const options = [
  { value: 'Fobesoft', label: 'Fobesoft' },
  { value: 'Autobuy', label: 'Autobuy' },
  { value: 'wepaythemax', label: 'wepaythemax' },
  { value: 'transcredit', label: 'transcredit' },
];
// for (let i = 10; i < 36; i++) {
//   options.push({
//     value: i.toString(36) + i,
//     label: i.toString(36) + i,
//   });
// }

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

export const Employee = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [data, setData] = useState([
    { employeeName: 'Thaha', role: 'Develober', email: 'thaha@jetzerp.com', status: '', action: '' },
    { employeeName: 'Muthu', role: 'Develober', email: 'muthu@jetzerp.com', status: '', action: '' },
  ]);

  const columns = useMemo(() => [
    {
      accessorKey: 'employeeName',
      header: 'Employee Name',
      size: 250,
    },
    {
      accessorKey: 'role',
      header: 'Role',
      size: 250,
    },
    {
      accessorKey: 'email',
      header: 'Email Id',
      size: 80,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 80,
      Cell: ({ row }) => (
        <div className="flex justify-center gap-3">
         <Switch size="small" defaultChecked onChange={onChange} />
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 80,
      Cell: ({ row }) => (
        <div className="flex justify-center gap-3">
          <FaRegEdit onClick={toggleModal}
            className="text-[19.5px] text-[#3b82f6] cursor-pointer"
          />
          <FaTrashAlt
            className="text-[16px] text-[#ff4d4d] cursor-pointer"
          />
        </div>
      ),
    }
  ], [data]);
  return (
    <>
    <section className="employee_section">
    <div>
        <h2 className="text-[20px] font-[500]">Employee</h2>
      </div>
      <div className="flex justify-start">
        <button
          onClick={toggleModal}
          className="bg-blue-500 text-white py-1.5 px-3 rounded flex items-center mb-4 relative top-[1.4rem] z-[9] left-[1rem]"
        >
          <span className="mr-2"><i class="fa fa-plus-circle text-[18px] relative top-[1px]"></i></span><span className="text-[14px]">Add Employee</span>
        </button>
      </div>
      <div className="mt-[-40px] overflow-auto custom-table" style={{ maxHeight: '400px' }}>
        <CustomTable
          columns={columns}
          data={data}
          pinColumn={['action']}
        />
      </div>
      {isModalOpen && (
        <Modal toggleModal={toggleModal}>
          <div className="bg-white py-4 rounded-lg max-w-lg mx-auto">
            <form >
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <label className="block text-gray-700 text-[15px] mb-1">Employee Name</label>
                  <Input className="h-9" />
                </div>

              </div>
              <div className="grid grid-cols-2 gap-3 mt-4">
                <div>
                  <label className="block text-gray-700 text-[15px] mb-1">Role</label>
                  <Select className="h-10"
                    defaultValue="member"
                    style={{ width: '100%' }}
                    onChange={handleChange}
                    options={[
                      { value: 'member', label: 'Team Member' },
                      { value: 'lead', label: 'Team Lead' },
                      { value: 'project', label: 'Project Manager' },
                    ]}
                  />
                </div>
                <div className="">
                <label className="block text-gray-700 text-[15px] mb-1">Email Id</label>
                <Input className="w-[100%] h-10" />
              </div>
              </div>
              <div className="mt-4">
                  <label className="block text-gray-700 text-[15px] mb-1">Assign Project</label>
                  <Select className="h-10"
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    defaultValue={['Fobesoft', 'Autobuy']}
                    onChange={handleChange}
                    options={options}
                  />
                </div>
        
              <div className="flex justify-end gap-3 mt-8">
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700 text-[15px]"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded text-[15px]"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </section>

    </>
  );
};
