import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { CiViewTimeline } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { BiSpreadsheet } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";

export const Sidebar = () => {
  return (
    <aside className="w-[160px] bg-[#fff] text-white flex flex-col items-center py-10" style={{borderRight:'1px solid #cfcfcf'}}>
      <div className='flex justify-start w-[80%]'>
        <img className='w-[6.5rem]' src='/jetz-logo.png' alt="logo" /></div>
      <ul className="w-full mt-[1.5rem]">
        <NavLink
          to="/timesheets">
          {({ isActive }) => (
            <li
              className={`py-2 px-6 cursor-pointer text-[14px] text-[#37474f] flex items-center gap-1 ${isActive ? "bg-[#e4eaee] text-[#37474f]" : "hover:bg-[transparent] "
                }`}
            >
             <div><BiSpreadsheet className='text-[20px]' /></div><div>Timesheets</div> 
            </li>
          )}
        </NavLink>
        <NavLink>
          {({ isActive }) => (
            <li
              className={`py-2 px-6 cursor-pointer text-[14px] flex items-center gap-1 text-[#37474f] ${isActive ? "bg-[#fff] text-[#37474f]" : "hover:bg-[transparent] "
                }`}
            >
                <div><AiOutlineSetting className='text-[19px]' /></div><div>Settings</div> <div> <IoIosArrowDown className='text-[15px]' /></div>
              
            </li>
          )}
        </NavLink>
        <NavLink
          to="/employee">
          {({ isActive }) => (
            <li
              className={`py-2 px-6 cursor-pointer text-[14px] ml-4 text-[#37474f] flex items-center gap-1.5 ${isActive ? "bg-[#e4eaee] text-[#37474f]" : "hover:bg-[transparent] "
                }`}
            >  <div><FaUserFriends className='text-[20px]' /></div><div>Employee</div> 
              
            </li>
          )}
        </NavLink>
        <NavLink
          to="/projects">
          {({ isActive }) => (
            <li
              className={`py-2 px-6 cursor-pointer text-[14px] ml-4 text-[#37474f] flex items-center gap-1.5 ${isActive ? "bg-[#e4eaee] text-[#37474f]" : "hover:bg-[transparent] "
                }`}
            ><div><FaFileAlt className='text-[16px]' /></div><div>Projects</div> 
              
            </li>
          )}
        </NavLink>
      </ul>
    </aside >
  );
};
