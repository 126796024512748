// src/components/constants/Header.js

import React from 'react';

export const Header = ({ user_name, initials, toggleSignOut, isSignOutVisible, handleSignOut }) => {
  return (
    <div className="absolute top-5 right-5 flex items-center gap-3 bg-white shadow-lg rounded-full border border-gray-200" style={{paddingInline:'12px 7px',paddingBlock:'7px'}}>
      <div className="text-gray-700 text-[15px] font-[500]">
        Hi, <span className="font-[500] text-blue-600">{user_name}</span>
      </div>
      <div
        className="bg-blue-500 text-white rounded-full w-9 h-9 flex items-center justify-center font-[500] text-[14px] cursor-pointer relative hover:bg-blue-600 transition-all"
        onClick={toggleSignOut}
        title="Click to sign out"
      >
        {initials}
      </div>
      {isSignOutVisible && (
        <div className="absolute top-full mt-2 right-0 bg-white border border-gray-300 rounded shadow-lg w-28 text-center">
          <button
            className="px-4 py-2 text-red-500 hover:bg-gray-100 w-full"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};
