// src/components/common/CustomTable.js

import React from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

const CustomTable = ({ columns, data, pinColumn }) => {

  console.log(pinColumn,"pinC")

  const table = useMaterialReactTable({
    data,
    columns,
    enableColumnFilter: false,
    enableFullScreenToggle: false, 
    enableColumnFilters: false,      
    enableDensityToggle: false,      
    enableColumnVisibility: false,  
    enableHiding: false,
 
    // positionPagination:'both',
    initialState: { pagination: { pageSize: 25, pageIndex: 2 } },

    state:{
      columnPinning: { right: pinColumn },
    }
  });

  return (
    <div className='custom-material'>
      <MaterialReactTable
        table={table}
        enableColumnFilters
        enableGlobalFilter
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20],
        }}
      />
    </div>
  );
};

export default CustomTable;
