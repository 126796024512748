// src/components/modules/Timesheet.js

import React, { useState, useMemo } from "react";
import CustomTable from "../common/CustomTable";
import { DatePicker, Input } from 'antd';
import { FaRegEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { Modal } from "../common/Modal";
import { Select, Typography } from 'antd';

const options = [];

for (let i = 0; i < 10; i++) {
  const value = `${i.toString(36)}${i}`;
  options.push({
    label: value,
    value,
    disabled: i === 10,
  });
}

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

export const Timesheet = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [data, setData] = useState([
    { taskName: '-', ProjectName: 'Autobuy', start_date: '03-10-2024', start_time: '10 AM', end_date: '24-10-2024', end_time: '5PM', assignedTo: 'Thaha' },
    { taskName: '-', ProjectName: 'Fobesoft', start_date: '10-10-2024', start_time: '11 AM', end_date: '18-10-2024', end_time: '3 PM', assignedTo: 'Luffy' },
  ]);
  const columns = useMemo(() => [
    {
      accessorKey: 'taskName',
      header: 'Task Name',
      size: 250,
    },
    {
      accessorKey: 'ProjectName',
      header: 'Project Name',
      size: 250,
    },
    {
      accessorKey: 'start_date',
      header: 'Start date',
      size: 80,
    },
    {
      accessorKey: 'start_time',
      header: 'Start Time',
      size: 80,
    },
    {
      accessorKey: 'end_date',
      header: 'End date',
      size: 80,
    },
    {
      accessorKey: 'end_time',
      header: 'End Time',
      size: 80,
    },
    {
      accessorKey: 'end_date',
      header: 'Action',
      size: 50,
      Cell: ({ cell }) => (
        <div className="flex justify-center gap-5">
          {/* onClick={toggleModal}  */}
          <FaRegEdit onClick={toggleModal} className="text-[18px] text-[#3b82f6] cursor-pointer" />
          <FaTrashAlt className="text-[16px] text-[#ff4d4d] cursor-pointer" />
        </div>
      ),
    },

  ], [data]);
  return (
    <>
      <div>
        <h2 className="text-[20px] font-[500]">Timesheet</h2>
      </div>

      <div className="mt-3 overflow-auto custom-table" style={{ maxHeight: '400px' }}>
        <CustomTable
          columns={columns}
          data={data}
          pinColumn={['action']}
        />
      </div>

      {isModalOpen && (
        <Modal toggleModal={toggleModal}>
          <div className="bg-white py-4 rounded-lg max-w-lg mx-auto">
            {/* <h3 className="text-[20px] font-[500] mb-4">Create new project</h3> */}
            <form >
              {/* Project Name */}
              <div className="mb-4 mt-4">
                <label className="block text-gray-700 text-[15px] mb-1">Select project</label>
                <Select className="h-10"
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue={['Autobuy', 'Fobesoft']}
                  onChange={handleChange}
                  options={options}
                />
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="block text-gray-700 text-[15px] mb-1">Start date</label>
                  <DatePicker className="w-[100%]" />
                </div>
                <div>
                  <label className="block text-gray-700 text-[15px] mb-1">End date</label>
                  <DatePicker className="w-[100%]" />
                </div>
                <div>
                  <label className="block text-gray-700 text-[15px] mb-1">Start time</label>
                  <Input className="w-[100%]" />
                </div>
                <div>
                  <label className="block text-gray-700 text-[15px] mb-1">End time</label>
                  <Input className="w-[100%]" />
                </div>
              </div>


              <div className="flex justify-end gap-3 mt-8">
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700 text-[15px]"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded text-[15px]"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};
