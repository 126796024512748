// src/components/modules/Report.js

import React from "react";

export const Report = () => {
  return (
    <div>
    <h2 className="text-[20px] font-[500]">Reports</h2>
  </div>
  );
};
