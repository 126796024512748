import React, { useState, useMemo } from "react";
import CustomTable from "../common/CustomTable";
import { FaTrashAlt, FaCheck } from "react-icons/fa";
import { LuPlusCircle } from "react-icons/lu";
import { FaRegCircleCheck } from "react-icons/fa6";

export const TimeSheetOne = () => {
  const [data, setData] = useState([
    { employeeName: 'Thaha', role: 'Developer', email: 'thaha@jetzerp.com', assignedTo: 'Thaha', isEditing: false },
    { employeeName: 'Muthu', role: 'Developer', email: 'muthu@jetzerp.com', assignedTo: 'Luffy', isEditing: false },
  ]);
  const columns = useMemo(() => [
    {
      accessorKey: 'employeeName',
      header: 'Employee Name',
      Cell: ({ cell, row }) => (
        <input 
          type="text"
          value={cell.row.original.employeeName}
          onChange={(e) => handleInputChange(e, row.index, 'employeeName')}
          className="custom-input w-[100%]"
          disabled={!cell.row.original.isEditing} // Disable input if not editing
        />
      ),
    },
    {
      accessorKey: 'role',
      header: 'Role',
      Cell: ({ cell, row }) => (
        <input 
          type="text"
          value={cell.row.original.role}
          onChange={(e) => handleInputChange(e, row.index, 'role')}
          className="custom-input"
          disabled={!cell.row.original.isEditing} // Disable input if not editing
        />
      ),
    },
    {
      accessorKey: 'email',
      header: 'Email Id',
      Cell: ({ cell, row }) => (
        <input 
          type="email"
          value={cell.row.original.email}
          onChange={(e) => handleInputChange(e, row.index, 'email')}
          className="custom-input"
          disabled={!cell.row.original.isEditing} // Disable input if not editing
        />
      ),
    },
    {
      accessorKey: 'assignedTo',
      header: 'Assigned To',
      size:250,
      Cell: ({ cell, row }) => (
        <select
          value={cell.row.original.assignedTo}
          onChange={(e) => handleInputChange(e, row.index, 'assignedTo')}
          className="custom-select h-[35px] w-full"
          disabled={!cell.row.original.isEditing} // Disable select if not editing
        >
          <option value="">Select User</option>
          <option value="Thaha">Thaha</option>
          <option value="Luffy">Luffy</option>
          <option value="Sanji">Sanji</option>
          <option value="Zoro">Zoro</option>
        </select>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size:50,
      Cell: ({ row }) => (
        <div className="flex justify-center gap-3">
          {row.original.isEditing ? (
            // Show check icon to save the row
            <FaRegCircleCheck
              className="text-[18.5px] text-[#2c922c] cursor-pointer"
              onClick={() => handleSaveRow(row.index)} // Save new row
            />
          ) : (
            // Show plus icon to add new row
            <LuPlusCircle 
              className="text-[19.5px] text-[#2c922c] cursor-pointer"
              onClick={handleAddRow} // Add new row
            />
          )}
          <FaTrashAlt
            className="text-[16px] text-[#ff4d4d] cursor-pointer"
            onClick={() => handleDeleteRow(row.index)} // Delete row
          />
        </div>
      ),
    },
  ], [data]);

  // Function to add a new row with empty values
  const handleAddRow = () => {
    const newRow = { employeeName: '', role: '', email: '', assignedTo: '', isEditing: true };
    setData(prevData => [...prevData, newRow]);
  };

  // Function to handle input change in the new row
  const handleInputChange = (e, rowIndex, field) => {
    const newData = [...data];
    newData[rowIndex][field] = e.target.value;
    setData(newData);
  };

  // Function to save the row and stop editing
  const handleSaveRow = (rowIndex) => {
    const newData = [...data];
    newData[rowIndex].isEditing = false; // Mark row as saved (not editing)
    setData(newData);
  };

  // Function to delete a row
  const handleDeleteRow = (rowIndex) => {
    const newData = data.filter((_, index) => index !== rowIndex);
    setData(newData);
  };



  return (
    <>
      <div className="container mx-auto py-5">
        <h2 className="text-[20px] font-[500]">Employee</h2>
      </div>
      <div className="mt-[-10px] overflow-auto custom-table" style={{ maxHeight: '400px' }}>
        <CustomTable
          columns={columns}
          data={data}
          pinColumn={['action']}
        />
      </div>
    </>
  );
};
